*, *::before, *::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    background: #FFFFF0;
}

.shape {
    margin: 0 auto;
    aspect-ratio: 1;
    position: relative;
    --width: 100%;
    --scale: 1;
    --opacity: 0.66;
    --top: 0;
    --left: 0;
    --path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    --background: linear-gradient(hotpink, red, orange, yellow, hotpink);
    --offset: 0deg;
    --speed: 8000ms;
    clip-path: var(--path);
    background: var(--background);
    scale: var(--scale);
    opacity: var(--opacity);
    width: var(--width);
    top: var(--top);
    left: var(--left);
    rotate: var(--offset);
    mix-blend-mode: difference;
    animation: turn var(--speed) linear forwards infinite;
}

@keyframes turn {
    to {
        rotate: calc(var(--offset) + 1turn);
    }
}

.blur-container {
    --blur: 40px;
    filter: blur(var(--blur));
    height: 100vh;
    width: 100%;
    display: grid;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; /* Keep the background behind the content */
    pointer-events: none; /* Disable interaction with the background */
}

.blur-container > * {
    grid-column: 1/-1;
    grid-row: 1/-1;
}

/* Wrapper for content, ensuring it appears above the background */
.content-wrapper {
    position: relative;
    z-index: 10; /* Bring the content on top of the background */
    color: white;
    height: 100vh; /* Take up full viewport height on mobile */
    overflow-y: auto; /* Allow vertical scrolling if content exceeds viewport height */
}
/* Styles for the header */
.stream-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    flex-wrap: wrap;
}

.stream-header .logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.stream-header .nav-links a {
    margin-left: 20px;
    color: white;
    text-decoration: none;
    font-size: 18px;
}

/* Main stream content */
.stream-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh; /* Use min-height for flexibility on mobile */
    color: white;
    padding: 20px;
}

.stream-container {
    display: flex; /* Change from grid to flex */
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 80%;
    max-width: 1200px;
}

.stream-player {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    overflow: hidden;
    max-width: 1920px;
    width: 100%;

}

.stream-player iframe {
    border: none;
    width: 100%;
    
    height: 599px;
}

/* Schedule styles */
.stream-schedule {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
}

.stream-schedule h2 {
    margin-bottom: 10px;
}

.stream-schedule ul {
    list-style-type: none;
    padding-left: 0;
    color: #6c757d;
}

.stream-schedule ul li {
    margin: 10px 0;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .stream-container {
        grid-template-columns: 1fr;
    }

    .stream-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .stream-header .nav-links a {
        margin: 10px 0;
        font-size: 16px;
    }

    .stream-player iframe {
        height: 300px;
    }

    .stream-content {
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .stream-header .logo {
        font-size: 20px;
    }

    .stream-header .nav-links a {
        font-size: 14px;
    }

    .stream-schedule {
        padding: 10px;
    }

    .stream-player iframe {
        height: 200px;
    }
}
/* BlurComponent.css */

/* Modal overlay to blur the background */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Slightly darker overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Ensure it's on top of everything */
    backdrop-filter: blur(10px); /* Blur background */
}

/* Modal content styling with new color scheme */
.modal-content {
    background: linear-gradient(135deg, #FFD89B, #F7AB5F, #FF6E7F); /* Gradient matching the color scheme */
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 550px;
    justify-content: center;
}

.modal-content h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFF0; /* Darker text color for contrast */
}

.modal-content input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.6); /* Slightly transparent input field */
}

.modal-content button {
    padding: 12px 200px;
    background-color: #FF6E7F;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
}

.modal-content button:hover {
    background-color: #F76C6C; /* Slightly darker on hover */
}
.schedule-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
  }
  
  .day-schedule {
    flex: 1;
    margin: 0 20px; /* Add some spacing between the two columns */
  }
  
  .schedule-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .schedule-section li {
    margin: 10px 0;
  }

  .centered-logo {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .conference-logo {
    max-width: 300px;
    width: 100%;
    height: auto;
  }
  
  .video-columns {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .video-column {
    flex: 1;
    text-align: center;
  }
  
  .video-column h3 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #000066; /* Optional: Customize title color */
  }
  
  .video-column iframe {
    width: 100%;
    height: 360px; /* Adjust height as needed */
    max-width: 640px; /* Set a max width for responsive behavior */
    border-radius: 8px; /* Optional: Add rounded corners to iframe */
  }