.section-detail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.section-header {
  margin-bottom: 3rem;
}

.back-button {
  display: inline-flex;
  align-items: center;
  color: #2196F3;
  text-decoration: none;
  font-weight: 500;
  margin-bottom: 1.5rem;
  transition: color 0.3s ease;
}

.back-button:hover {
  color: #1976D2;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.section-icon {
  font-size: 3rem;
}

.section-title h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(45deg, #2196F3, #21CBF3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
  max-width: 800px;
}

.section-content {
  display: grid;
  gap: 3rem;
}

.content-block {
  background: white;
  border-radius: 1.5rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.content-block:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(33, 150, 243, 0.15);
}

.content-block h2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #2196F3;
  margin: 0 0 1rem 0;
}

.content-block p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  margin: 0 0 2rem 0;
}

.steps-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 1rem;
}

.step-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: rgba(33, 150, 243, 0.05);
  border-radius: 1rem;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.step-item:hover {
  transform: translateX(10px);
  background: rgba(33, 150, 243, 0.1);
}

.step-number {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2196F3;
  color: white;
  font-weight: 600;
  border-radius: 50%;
  flex-shrink: 0;
}

.step-text {
  font-size: 1.1rem;
  color: #333;
}

@media (max-width: 768px) {
  .section-detail {
    padding: 1rem;
  }

  .section-title {
    gap: 0.5rem;
  }

  .section-icon {
    font-size: 2rem;
  }

  .section-title h1 {
    font-size: 1.8rem;
  }

  .section-description {
    font-size: 1rem;
  }

  .content-block {
    padding: 1.5rem;
  }

  .content-block h2 {
    font-size: 1.5rem;
  }

  .content-block p {
    font-size: 1rem;
  }

  .step-item {
    padding: 0.75rem;
  }

  .step-text {
    font-size: 1rem;
  }
} 