.search-container {
  position: relative;
}

.search-icon-button {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-icon-button:hover {
  color: #2196F3;
}

.search-icon {
  width: 20px;
  height: 20px;
}

.search-popup {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
  transition: all 0.3s ease;
  z-index: 1000;
  margin-top: 0.5rem;
  min-width: 300px;
}

.search-popup::before {
  content: '';
  position: absolute;
  top: -6px;
  right: 10px;
  width: 12px;
  height: 12px;
  background: white;
  transform: rotate(45deg);
  box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.05);
}

.search-popup.open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.search-input {
  width: 100%;
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 2rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: white;
}

.search-input:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
}

.clear-search {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}

.clear-search:hover {
  color: #2196F3;
}

@media (max-width: 768px) {
  .search-popup {
    position: fixed;
    top: 72px;
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 0;
    min-width: auto;
    padding: 1rem;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .search-popup::before {
    display: none;
  }

  .search-input {
    padding: 0.75rem 2rem 0.75rem 1rem;
    font-size: 0.9rem;
  }

  .clear-search {
    right: 1.25rem;
    font-size: 1.25rem;
  }
}

.search-results {
  margin-top: 1rem;
  border-top: 1px solid #e0e0e0;
  max-height: 300px;
  overflow-y: auto;
}

.search-result-item {
  padding: 0.75rem;
  border-bottom: 1px solid #f0f0f0;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.search-result-item:hover {
  background-color: #f8f9fa;
}

.search-highlight {
  background: rgba(33, 150, 243, 0.1);
  color: #2196F3;
  padding: 0.1rem 0.2rem;
  border-radius: 2px;
  font-weight: 500;
} 