.nosotros-header-comp {
  .react-parallax-bgimage {
    // transform: translate3d(-50%, -15%, 0px) !important;
  }

  .breadcrumb-fulllife {
    background-image: url("https://hub.iglesiafulllife.org/wp-content/uploads/2024/02/fotos-pas-scaled.jpg") !important;
  }
  .nosotros-header > div {
    // background-position: center center !important;
    // background-size: 150% !important; /* Adjust as needed */
    max-height: 555px;
  }
  .slide.slide-style-2 .inner h1.title {
    font-size: 65px !important;
  }
  .react-parallax-content {
    div.slider-paralax {
      padding-bottom: 240px;
    }
  }
}
