.new-member-info {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.new-member-title {
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 3rem;
  background: linear-gradient(45deg, #2196F3, #21CBF3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.new-member-title::after {
  content: '';
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(45deg, #2196F3, #21CBF3);
  border-radius: 2px;
}

.info-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.info-item {
  height: 100%;
  perspective: 1000px;
}

.info-card {
  height: 100%;
  background: white;
  border-radius: 1.5rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform-style: preserve-3d;
  transform: translateZ(0);
}

.info-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(45deg, #2196F3, #21CBF3);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.info-card:hover {
  transform: translateY(-5px) translateZ(20px);
  box-shadow: 0 8px 15px rgba(33, 150, 243, 0.15);
}

.info-card:hover::before {
  transform: scaleX(1);
}

.info-card-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  transform: translateZ(30px);
  transition: transform 0.3s ease;
}

.info-card:hover .info-card-icon {
  transform: translateZ(40px) scale(1.1);
}

.info-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2196F3;
  margin: 0;
  transform: translateZ(20px);
  transition: transform 0.3s ease;
}

.info-card:hover .info-card-title {
  transform: translateZ(30px);
}

.info-card-desc {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  margin: 0;
  flex-grow: 1;
  transform: translateZ(10px);
  transition: transform 0.3s ease;
}

.info-card:hover .info-card-desc {
  transform: translateZ(20px);
}

.info-card-number {
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
  font-size: 3rem;
  font-weight: 700;
  color: rgba(33, 150, 243, 0.1);
  transition: all 0.3s ease;
  transform: translateZ(0);
}

.info-card:hover .info-card-number {
  color: rgba(33, 150, 243, 0.2);
  transform: translateZ(10px) scale(1.1);
}

@media (max-width: 768px) {
  .new-member-info {
    padding: 1rem;
  }

  .new-member-title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .info-list {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .info-card {
    padding: 1.5rem;
  }

  .info-card-title {
    font-size: 1.25rem;
  }

  .info-card-desc {
    font-size: 1rem;
  }

  .info-card-number {
    font-size: 2.5rem;
    right: 1rem;
    bottom: 1rem;
  }
} 